@media (max-width: 390px) {
  .bank-selector-container {
    padding: 10px;
    border-left: 20px solid #07122e;
    border-right: 20px solid #07122e;
    border-bottom: 20px solid #07122e;
  }

  .country-selection {
    flex-direction: column;
    gap: 5px;
  }

  .country-dropdown {
    width: 20% !important;
    min-width: 160px;
  }

  .bank-search-input {
    width: 100%;
  }
  /* 
  .bank-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  } */

  .bank-card {
    width: 100%;
  }

  .main-card {
    padding: 15px;
    margin: 0 auto;
    width: 100%;
  }
}
@media (max-width: 412px) {
  .bank-selector-container {
    padding: 0px;
    border-left: 10px solid #07122e;
    border-right: 10px solid #07122e;
    border-bottom: 10px solid #07122e;
  }

  .main-card {
    padding: 15px;
    margin: 0 auto;
    width: 100%;
  }

  .country-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .country-dropdown {
    width: 80% !important;
    min-width: 150px;
    max-width: 350px;
  }

  .bank-search-input {
    width: 100%;
    max-width: 350px;
  }
}

@media (min-width: 413px) {
  .bank-selector-container {
    padding: 20px;
    border-left: 20px solid #07122e;
    border-right: 20px solid #07122e;
    border-bottom: 20px solid #07122e;
  }

  .country-dropdown {
    width: 320px !important;
  }

  .bank-search-input {
    width: 320px; 
  }

  .bank-card {
    width: 100%;
    max-width: 800px;
  }
}
@media (max-width: 600px) {
  .header {
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header img {
    width: 150px;
  }
}

.header {
  background-color: #07122e;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 420px;  
  padding-right: 420px; 
  
}

@media only screen and (max-width: 1785px) and (max-height: 929px) {
  .header {
    padding-left: 100px;  
    padding-right: 100px; 
  }
}

@media only screen and (max-width: 820px) and (max-height: 1180px) {
  .header {
  display: flex;
  justify-content: center;
  }
}

  .bank-selector-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #07122e;
    padding-top: 20px;
  }

.main-card {
  height: calc(100vh - 220px);
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 30px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  width: 1000px;
  max-width: 90%;
  text-align: center;
  overflow: hidden;
  transition: all 0.3s ease;
}

.back-button {
  background-color: transparent;
  color: #2e2e2f;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
}

.back-button:hover {
  color: #6e8efb;
  text-decoration: underline;
}

.country-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.country-button {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 11px 22px;
  font-size: 16px;
  border: 1px solid #b5c2eb;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: calc(50% - 20px);
  margin: 10px;
  box-sizing: border-box;
}

.country-button:hover {
  border-color: #6e8efb;
  box-shadow: 0px 4px 8px rgba(110, 142, 251, 0.3);
}

.country-flag {
  width: 30px;
  height: auto;
  margin-right: 6px;
  vertical-align: middle;
}

.bank-flag {
  display: flex;
  align-items: center;
  padding: 0.8rem 0.5rem;
  border: 1px solid var(--primary-cta-200);
  border-radius: 5px;
  width: 40px;
  height: auto;
  vertical-align: middle;
}

.bank-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 350px;
  overflow-y: auto;
  gap: 20px;
  padding: 10px 0 60px;
  margin-top: 10px;
}

.bank-card {
  flex: 0 0 calc(50% - 20px);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  color: #fff;
}

.bank-button {
  width: 100%;
  padding: 15px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #f0f0f0;
  border: 1px solid #0d0e0f;
  transition: background-color 0.3s ease;
}

.bank-button h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}

.search-input {
  width: 30%;
  padding: 12px;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.country-dropdown {
  width: 30%;
}

.bank-search-input {
  width: 70% !important;

  padding: 9px !important;
  border-radius: 8px;
  font-size: 16px;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 0 !important;
}

.country-button,
.bank-button {
  display: flex;
  align-items: center;
}

.arrow {
  font-size: 15px;
  margin-left: auto;
  border: 5px solid transparent;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  font-size: 24px;
  color: white;
}

.country-button {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #2e2e2f;
  padding: 11px 22px;
  font-size: 16px;
  border: 1px solid #b5c2eb;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: calc(50% - 20px);
  margin: 10px;
  box-sizing: border-box;
  border-radius: 8px;
}

.country-button:hover {
  background-color: #e7f0ff;
  border-color: #6e8efb;
  box-shadow: 0px 4px 8px rgba(110, 142, 251, 0.3);
}

.bank-button {
  width: 100%;
  padding: 15px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #f0f0f0;
  border: 1px solid #0d0e0f;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  color: #2e2e2f;
}

.bank-button:hover {
  background-color: #d1d1d1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.tab-navigation {
  display: flex;
  width: 100%;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

.tab-button {
  flex: 1;
  padding: 15px 0;
  background-color: #ffffff;
  border: none;
  border-right: 1px solid #ddd;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #2e2e2f;
}

.tab-button:last-child {
  border-right: none;
}

.tab-button.active {
  background-color: #0f114a;
  color: #ffffff;
  font-weight: bold;
}

.tab-button:not(.active):hover {
  background-color: #f0f0f0;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
  background-color: rgba(70, 70, 70, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

.popup-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 20px 30px;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  animation: fade-in-out 3s ease-in-out;
  max-width: 90%;
  text-align: center;
}

.popup-message.error {
  background-color: #f44336;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bank-button,
.country-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
}

.bank-button:hover,
.country-button:hover {
  background-color: #e0e0e0;
}

.search-input,
.bank-search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.tab-navigation {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.tab-button.active {
  background-color: #4caf50;
  color: white;
}

@media (max-width: 768px) {
  .main-card {
    width: 90%;
  }

  .bank-card {
    flex: 0 0 100%;
  }

  .country-button {
    flex: 0 0 calc(50% - 20px);
  }

  .country-list {
    justify-content: center;
  }
}
